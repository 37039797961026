import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import PageLoading from "../../components/ui/loader/pageLoading";
import Button from "../../components/ui/button/Button";
import Search from "../../components/ui/search/Search";
import Table from "../../components/ui/table/Table";
import plus from "../../assets/icons/plus.svg";
import edit from "../../assets/icons/edit.png";
import trash from "../../assets/icons/trash.png";

function ConsultantsList() {
  const { consultants } = useContext(AppContext);

  const [filteredConsultants, setFilteredConsultants] = useState(
    consultants.data
  );

  const navigate = useNavigate();

  useEffect(() => {
    setFilteredConsultants(consultants.data);
  }, [consultants.data]);

  return consultants.loading ? (
    <PageLoading />
  ) : (
    <div className="consultants-list">
      <div className="consultants-list-header">
        <Search
          searchKey="name"
          items={consultants.data}
          setItems={setFilteredConsultants}
          placeholder="search by consultant's name"
        />
        <Button
          icon={plus}
          text="New Consultant"
          color="#fff"
          backgroundColor="#06D6A0"
          big
          onClick={() => navigate("/admin/consultants/add")}
        />
      </div>
      <div className="consultants-list-table">
        <Table
          tableHead={[
            { key: 1, value: "Consultant's Name" },
            { key: 2, value: "Hospital" },
            { key: 3, value: "Mobile" },
            { key: 4, value: "Email" },
            { key: 5, value: "Joining Date" },
            { key: 6, value: "Action" },
          ]}
          tableBody={filteredConsultants.map((data) => ({
            key: data.id,
            entry: [
              { key: 1, value: data.name },
              { key: 2, value: data.hospital },
              { key: 3, value: data.phone },
              { key: 4, value: data.email },
              { key: 5, value: new Date(data.created_at).toLocaleDateString() },
              {
                key: 6,
                value: (
                  <div style={{ display: "flex" }}>
                    <Button icon={edit} />
                    <Button icon={trash} />
                  </div>
                ),
              },
            ],
          }))}
          darkHead
        />
      </div>
    </div>
  );
}

export default ConsultantsList;
