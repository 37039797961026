import clock from "../../../assets/icons/u_clock-three.svg";
import mark from "../../../assets/icons/fi_check.svg";
import "./cards.css";

function SentMessageCard({ text, initial, loading, timestamp }) {
  return (
    <div
      className={`message-card sent-message-card ${initial ? "initial" : ""}`}
    >
      <p className="text">{text}</p>
      <p className="time">
        {timestamp && (
          <span>
            {new Date(timestamp).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        )}
        <img src={loading ? clock : mark} alt="" />
      </p>
    </div>
  );
}

export default SentMessageCard;
