import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import Dashboardlayout from "../../components/layout";
import PageLoading from "../../components/ui/loader/pageLoading";
import angleDown from "../../assets/icons/angleDown.png";
import "./consultantPatients.css";

// hooks
import useUpdateConsultants from "../../hooks/consultants";
import notify from "../../utils/notify";

function ConsultantPatientDetails() {
  const [selectedPatient, setSelectedPatient] = useState();
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(0);
  const { consultantPatients } = useContext(AppContext);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { updateConsultantPatients } = useUpdateConsultants();

  const onClickDetails = (value) => {
    setShowDetails((preValue) => {
      if (preValue === value) return 0;
      return value;
    });
  };

  useEffect(() => {
    // fetch patients
    if (consultantPatients.loading) {
      updateConsultantPatients();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const foundPatient = consultantPatients.data.find((cp) => cp.uuid === uuid);

    if (foundPatient) {
      setSelectedPatient(foundPatient);
      setLoading(false);
    }

    if (!consultantPatients.loading && !foundPatient) {
      notify("error", "patient not found");
      navigate("/consultant/patients");
    }

    // eslint-disable-next-line
  }, [consultantPatients, uuid]);

  return (
    <Dashboardlayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="patient-details">
          <div className={`details-group ${showDetails === 1 ? "show" : ""}`}>
            <h4
              onClick={() => {
                onClickDetails(1);
              }}
            >
              Patient Details <img src={angleDown} alt="" />
            </h4>
            <div className="details-content">
              <div className="details-row">
                <p className="label">Full Name:</p>
                <p className="value">{selectedPatient.name}</p>
              </div>
              <div className="details-row">
                <p className="label">Age:</p>
                <p className="value">
                  {Math.floor(
                    (new Date() - new Date(selectedPatient.dob)) / 31536000000
                  )}
                  &nbsp; years
                </p>
              </div>
              <div className="details-row">
                <p className="label">Gender:</p>
                <p className="value">{selectedPatient.gender}</p>
              </div>
              <div className="details-row">
                <p className="label">Phone no:</p>
                <p className="value">{selectedPatient.phone}</p>
              </div>
              <div className="details-row">
                <p className="label">Weight:</p>
                <p className="value">{selectedPatient.weight}</p>
              </div>
              <div className="details-row">
                <p className="label">Height:</p>
                <p className="value">{selectedPatient.height}</p>
              </div>
              <div className="details-row">
                <p className="label">IOP:</p>
                <p className="value">{selectedPatient.iop}</p>
              </div>
              <div className="details-row">
                <p className="label">BP:</p>
                <p className="value">{selectedPatient.bp}</p>
              </div>
              <div className="details-row">
                <p className="label">Consultant:</p>
                <p className="value">{selectedPatient.doctor_name}</p>
              </div>
            </div>
          </div>
          <div className={`details-group ${showDetails === 2 ? "show" : ""}`}>
            <h4
              onClick={() => {
                onClickDetails(2);
              }}
            >
              Fundus Images <img src={angleDown} alt="" />
            </h4>
            <div className="details-content">
              <div className="details-images">
                <div className="details-image">
                  <p>Right Eye:</p>
                  <img src={selectedPatient.image?.fundus_re_url} alt="" />
                </div>
                <div className="details-image">
                  <p>Left Eye:</p>
                  <img src={selectedPatient.image?.fundus_le_url} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className={`details-group ${showDetails === 3 ? "show" : ""}`}>
            <h4
              onClick={() => {
                onClickDetails(3);
              }}
            >
              External Eye <img src={angleDown} alt="" />
            </h4>
            <div className="details-content">
              <div className="details-images">
                <div className="details-image">
                  <p>Right Eye:</p>
                  <img
                    src={selectedPatient.image?.external_eye_re_url}
                    alt=""
                  />
                </div>
                <div className="details-image">
                  <p>Left Eye:</p>
                  <img
                    src={selectedPatient.image?.external_eye_le_url}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </Dashboardlayout>
  );
}

export default ConsultantPatientDetails;
