import toast from "react-hot-toast";

const notify = (type, message) => {
  if (type === "success") {
    toast.success(message, {
      style: {
        border: "1px solid #06D6A0",
        padding: "10px 25px",
        color: "#06D6A0",
      },
      iconTheme: {
        primary: "#06D6A0",
        secondary: "#ffffff",
      },
    });
  }
  if (type === "error") {
    toast.error(message, {
      style: {
        border: "1px solid #be1e2d",
        padding: "10px 25px",
        color: "#be1e2d",
      },
      iconTheme: {
        primary: "#be1e2d",
        secondary: "#ffffff",
      },
    });
  }
};

export default notify;
