/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";
import { getAccessToken } from "../accessToken";

class Video {
  async callUser({ conversationId, messageableId }) {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/video/call-user`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        conversation_id: conversationId,
        user_id: messageableId,
      }),
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to create consultant` };
  }
}

const instance = new Video();

export default instance;
