/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react";
import { ReactComponent as EyeOpen } from "../../../assets/icons/eyeOpen.svg";
import { ReactComponent as EyeClose } from "../../../assets/icons/eyeClose.svg";
import people from "../../../assets/icons/people.svg";
import lock from "../../../assets/icons/lock.svg";
import "../form.css";
import "./formInput.css";
function FormInput({
  label, // optional
  subLabel, // optional
  bottomLabel, // optional
  dark, //optional
  type,
  name,
  value,
  onChange,
  required, // optional
  placeholder, // optional
  error,
  disabled, // optional
  small, // optional
  leftLabel, //optional
  showIcon, //optional
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className={`FormInput ${small ? "FormInput-small" : ""} ${
        leftLabel ? "FormInput-leftlabel" : ""
      }`}
    >
      {leftLabel && (
        <p className="left-label">
          {leftLabel}
          {required && <span>&nbsp;*</span>}
        </p>
      )}
      <div>
        {label && (
          <div className="label-wrapper">
            <label htmlFor={name}>{label}</label>
          </div>
        )}
        {subLabel && <p className="subLabel">{subLabel}</p>}
        <div className={`input-wrapper ${dark ? "dark" : ""}`}>
          {showIcon && <img src={type === "email" ? people : lock} alt="" />}
          <input
            className={showIcon ? "hasIcon" : ""}
            type={type === "password" && showPassword ? "text" : type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
          />
          {type === "password" && showPassword && (
            <EyeOpen onClick={() => setShowPassword(false)} />
          )}
          {type === "password" && !showPassword && (
            <EyeClose onClick={() => setShowPassword(true)} />
          )}
        </div>
        {bottomLabel && <p className="bottom-label">{bottomLabel}</p>}
        {error && <p className="formfeedback">{error}</p>}
      </div>
    </div>
  );
}

export default FormInput;
