import ConsultantDashboard from "../../pages/consultantDashboard/ConsultantDashboard";
import ConsultantPatients from "../../pages/consultantPatients/ConsultantPatients";
import ConsultantPatientDetails from "../../pages/consultantPatients/ConsultantPatientDetails";
import Chats from "../../pages/chats/Chats";
import ChatBase from "../../pages/chats/ChatBase";
import ChatDetail from "../../pages/chats/ChatDetail";
import ConsultantProfile from "../../pages/consultantProfile/ConsultantProfile";

const consultantRoutes = [
  {
    path: "/consultant/dashboard",
    component: ConsultantDashboard,
    protected: true,
    role: "doctor",
  },
  {
    path: "/consultant/patients",
    component: ConsultantPatients,
    protected: true,
    role: "doctor",
  },
  {
    path: "/consultant/patients/:uuid",
    component: ConsultantPatientDetails,
    protected: true,
    role: "doctor",
  },
  {
    path: "/consultant/consultations",
    component: Chats,
    protected: true,
    role: "doctor",
    nestedRoute: [
      {
        path: "",
        component: ChatBase,
      },
      {
        path: ":conversationId/messages",
        component: ChatDetail,
      },
    ],
  },
  {
    path: "/consultant/profile",
    component: ConsultantProfile,
    role: "doctor",
    protected: true,
  },
];

export default consultantRoutes;
