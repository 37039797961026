import "./loader.css";

function PageLoading() {
  return (
    <div className="page-loading">
      <p>Loading...</p>
    </div>
  );
}

export default PageLoading;
