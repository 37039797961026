import { useContext } from "react";
import { AppContext } from "../AppContext";
import Chats from "../utils/apiServices/chats";
import Diagnosis from "../utils/apiServices/diagnosis";
import notify from "../utils/notify";

function useUpdateChats() {
  const { conversations, setConversations, setMessages, setDiagnoses } =
    useContext(AppContext);

  function updateConversations() {
    setConversations({
      ...conversations,
      loading: true,
    });

    Chats.getConversations()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setConversations({
            data: data.conversations.data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setConversations({
            ...conversations,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setConversations({
          ...conversations,
          loading: false,
        });
      });
  }

  function updateConversationMessages({
    conversationId,
    participantId,
    participantType,
  }) {
    Chats.getSingleConversation({
      conversationId,
      participantId,
      participantType,
    })
      .then((res) => {
        const { data } = res;

        setMessages((prevMessages) => ({
          ...prevMessages,
          [conversationId]: data,
        }));
      })
      .catch(() => {
        notify("error", "connection error");
      });
  }

  function updateDiagnoses({ patientId }) {
    Diagnosis.getPaitientDiagnosis({
      patientId,
    })
      .then((res) => {
        const { data } = res;

        setDiagnoses((prevDiagnoses) => ({
          ...prevDiagnoses,
          [patientId]: data,
        }));
      })
      .catch(() => {
        notify("error", "connection error");
      });
  }

  return { updateConversations, updateConversationMessages, updateDiagnoses };
}

export default useUpdateChats;
