import { ReactComponent as Menu } from "../../../assets/icons/menu.svg";
import "./PanelHeader.css";

function PanelHeader({ page, onClickMenu }) {
  const pageTitle = {
    dashboard: "Dashboard",
    patients: "Patients",
    consultations: "Consultations",
    profile: "Profile",
  };

  return (
    <div className="PanelHeader">
      <div className="panel-header-left">
        <Menu onClick={onClickMenu} />
        <h3>{pageTitle[page] || "Not Found"}</h3>
      </div>
      <div className="panel-header-right" />
    </div>
  );
}

export default PanelHeader;
