/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";

class Authentication {
  async login(data) {
    const response = await fetch(`${baseURL}/auth/login`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok || response.status === 401) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to login` };
  }

  async logout() {
    sessionStorage.clear();
    localStorage.clear();

    return { status: true, message: "logout successful" };
  }
}

const instance = new Authentication();

export default instance;
