import { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../../AppContext";
import AdminDashboardlayout from "../../components/adminLayout";
import useUpdateHealthCenters from "../../hooks/healthCenters";
import "../consultants/consultants.css";
import "./healthCenters.css";

function HealthCenters() {
  const { healthCenters } = useContext(AppContext);
  const { updateHealthCenters } = useUpdateHealthCenters();

  useEffect(() => {
    if (healthCenters.loading) {
      updateHealthCenters();
    }

    // eslint-disable-next-line
  }, []);
  return (
    <AdminDashboardlayout>
      <Outlet />
    </AdminDashboardlayout>
  );
}

export default HealthCenters;
