import React, { useContext, useState, useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { AppContext } from "../AppContext";
import PageLoading from "../components/ui/loader/pageLoading";
import routes from "./routes";
import Redirect from "./Redirect";
import { getAccessToken, getAdminAccessToken } from "../utils/accessToken";

// api service
import Profile from "../utils/apiServices/profile";

function Router() {
  const [userLoading, setUserLoading] = useState(true);

  const [adminLoading, setAdminLoading] = useState(true);

  const { user, setUser, admin, setAdmin } = useContext(AppContext);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {routes.map((route) => {
          if (route.protected && route.role === "admin" && admin.loggedIn) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              >
                {route.nestedRoute?.map((nRoute) => (
                  <Route
                    key={nRoute.path}
                    path={nRoute.path}
                    element={<nRoute.component />}
                  />
                ))}
              </Route>
            );
          }

          if (
            route.protected &&
            route.role === "user" &&
            user.loggedIn &&
            user.role === "user"
          ) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              >
                {route.nestedRoute?.map((nRoute) => (
                  <Route
                    key={nRoute.path}
                    path={nRoute.path}
                    element={<nRoute.component />}
                  />
                ))}
              </Route>
            );
          }

          if (
            route.protected &&
            route.role === "doctor" &&
            user.loggedIn &&
            user.role === "doctor"
          ) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              >
                {route.nestedRoute?.map((nRoute) => (
                  <Route
                    key={nRoute.path}
                    path={nRoute.path}
                    element={<nRoute.component />}
                  />
                ))}
              </Route>
            );
          }

          if (route.protected && (!admin.loggedIn || !user.loggedIn)) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<Redirect />}
              />
            );
          }

          return (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            />
          );
        })}
      </Route>
    )
  );

  // get user profile
  useEffect(() => {
    if (getAccessToken() && !user.loggedIn) {
      Profile.getProfile("user")
        .then((res) => {
          if (res.status) {
            const { data } = res;

            // set user data
            setUser({
              id: data.user.id,
              uuid: data.user.uuid,
              email: data.user.email,
              name: data.user.name,
              phone: data.user.phone,
              address: data.user.address,
              hospital: data.user.hospital,
              created_at: data.user.created_at,
              profile_picture: data.user.profile_photo_url,
              loggedIn: true,
              role: data.role,
            });

            setUserLoading(false);
          } else {
            localStorage.removeItem("accessToken");
            sessionStorage.removeItem("accessToken");
            setUserLoading(false);
          }
        })
        .catch(() => {
          setUserLoading(false);
        });
    } else {
      setUserLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  // get admin profile
  useEffect(() => {
    if (getAdminAccessToken() && !admin.loggedIn) {
      Profile.getProfile("admin")
        .then((res) => {
          if (res.status) {
            const { data } = res;

            // set admin data
            setAdmin({
              id: data.user.id,
              uuid: data.user.uuid,
              email: data.user.email,
              name: data.user.name,
              phone: data.user.phone,
              profile_picture: data.user.profile_photo_url,
              loggedIn: true,
            });

            setAdminLoading(false);
          } else {
            localStorage.removeItem("adminAccessToken");
            sessionStorage.removeItem("adminAccessToken");
            setAdminLoading(false);
          }
        })
        .catch(() => {
          setAdminLoading(false);
        });
    } else {
      setAdminLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return userLoading || adminLoading ? (
    <PageLoading />
  ) : (
    <RouterProvider router={router} />
  );
}

export default Router;
