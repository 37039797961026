import Login from "../../pages/login/Login";
import adminRoutes from "./admin";
import consultantRoutes from "./consultant";
import hcRoutes from "./healthCenter";
import Redirect from "../Redirect";

const routes = [
  {
    path: "/",
    component: Login,
    protected: false,
  },
  ...adminRoutes,
  ...consultantRoutes,
  ...hcRoutes,
  {
    path: "*",
    component: Redirect,
    protected: true,
  },
];

export default routes;
