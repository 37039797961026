import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import PageLoading from "../../../components/ui/loader/pageLoading";
import search from "../../../assets/icons/search.svg";
import joseph from "../../../assets/images/joseph.png";

function ChatList() {
  const { user, conversations } = useContext(AppContext);
  const [conversationList, setConversationList] = useState(conversations.data);
  const navigate = useNavigate();
  const { conversationId } = useParams();

  const baseUrl = {
    user: "health-center",
    doctor: "consultant",
  };

  const onSearch = (e) => {
    const filteredConversations = conversations.data.filter((conversation) =>
      conversation.conversation.other_user.messageable.name
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    );
    setConversationList(filteredConversations);
  };

  useEffect(() => {
    setConversationList(conversations.data);
  }, [conversations.data]);

  return (
    <div className="chat-list">
      <div className="search">
        <input
          placeholder="Search for a Patient"
          type="text"
          onChange={onSearch}
        />
        <img src={search} alt="" />
      </div>
      {conversations.loading && !conversations.data.length ? (
        <PageLoading />
      ) : (
        <div className="chat-tiles">
          {conversationList.map((conversation) => (
            <div
              key={conversation.id}
              className={`chat-tile ${
                conversationId === String(conversation.conversation_id)
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                navigate(
                  `/${baseUrl[user.role]}/consultations/${
                    conversation.conversation_id
                  }/messages?participant_id=${user.id}&messageable_id=${
                    conversation.conversation.other_user.messageable_id
                  }&participant_type=${"App\\Models\\User"}`
                )
              }
            >
              <img
                src={
                  conversation.conversation.other_user.messageable
                    .profile_photo_url || joseph
                }
                alt=""
              />
              <div>
                <p>{conversation.conversation.data.title}</p>
                <p>
                  by {conversation.conversation.other_user.messageable.name}
                </p>
                <p>{conversation.conversation.last_message?.body}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ChatList;
