import { ReactComponent as Menu } from "../../../assets/icons/menu.svg";
import "./PanelHeader.css";

function PanelHeader({ page, subpage, onClickMenu }) {
  const getDisplay = () => {
    if (page === "consultants" && subpage === "add") {
      return "addconsultant";
    }

    if (page === "healthcenters" && subpage === "add") {
      return "addhealthcenter";
    }

    return page;
  };
  const pageTitle = {
    dashboard: "Dashboard",
    consultants: "Consultants",
    addconsultant: "Add Consultant",
    healthcenters: "Health Centers",
    addhealthcenter: "Add Health Center",
  };

  return (
    <div className="PanelHeader">
      <div className="panel-header-left">
        <Menu onClick={onClickMenu} />
        <h3>{pageTitle[getDisplay()] || "Not Found"}</h3>
      </div>
      <div className="panel-header-right" />
    </div>
  );
}

export default PanelHeader;
