import { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../../AppContext";
import AdminDashboardlayout from "../../components/adminLayout";
import useUpdateConsultants from "../../hooks/consultants";
import "./consultants.css";

function Consultants() {
  const { consultants } = useContext(AppContext);
  const { updateConsultants } = useUpdateConsultants();

  useEffect(() => {
    if (consultants.loading) {
      updateConsultants();
    }

    // eslint-disable-next-line
  }, []);
  return (
    <AdminDashboardlayout>
      <div className="consultants">
        <Outlet />
      </div>
    </AdminDashboardlayout>
  );
}

export default Consultants;
