import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import PageLoading from "../../components/ui/loader/pageLoading";
import AdminDashboardlayout from "../../components/adminLayout";
import AnalyticsCard from "../../components/ui/cards/AnalyticsCard";
import Table from "../../components/ui/table/Table";
import user from "../../assets/icons/user.svg";
import userMultiple from "../../assets/icons/user-multiple.svg";
import "./adminDashboard.css";

// hooks
import useUpdatePatients from "../../hooks/patients";
import useUpdateHealthCenters from "../../hooks/healthCenters";
import useUpdateConsultants from "../../hooks/consultants";
import useUpdateAppointments from "../../hooks/appointments";

function AdminDashboard() {
  const { patients, healthCenters, consultants, appointments } =
    useContext(AppContext);
  const { updatePatients } = useUpdatePatients();
  const { updateHealthCenters } = useUpdateHealthCenters();
  const { updateConsultants } = useUpdateConsultants();
  const { updateAppointments } = useUpdateAppointments();

  const loading =
    patients.loading ||
    healthCenters.loading ||
    consultants.loading ||
    appointments.loading;

  useEffect(() => {
    // fetch patients, health centers, consultants and appointments
    if (patients.loading) {
      updatePatients();
    }
    if (healthCenters.loading) {
      updateHealthCenters();
    }
    if (consultants.loading) {
      updateConsultants();
    }
    if (appointments.loading) {
      updateAppointments();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <AdminDashboardlayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="admin-dashboard">
          <div className="analytics-cards">
            <AnalyticsCard
              icon={user}
              value={patients.data.length}
              name="All Patients"
              iconBackgroundColor="#06D6A0"
            />
            <AnalyticsCard
              icon={userMultiple}
              value={healthCenters.data.length}
              name="All Health Center"
              iconBackgroundColor="#F2994A"
            />
            <AnalyticsCard
              icon={user}
              value={consultants.data.length}
              name="All Consultants"
              iconBackgroundColor="#EB5757"
            />
          </div>
          <div className="appointments-table">
            <Table
              tableHead={[
                { key: 1, value: "Patient's Name" },
                { key: 2, value: "Patient Phone" },
                { key: 3, value: "Doctor's Name" },
                { key: 4, value: "Doctor's Phone" },
                { key: 5, value: "Date" },
              ]}
              tableBody={appointments.data.map((data) => ({
                key: data.id,
                entry: [
                  { key: 1, value: data.patient.name },
                  { key: 2, value: data.patient.phone },
                  { key: 3, value: data.doctor.name },
                  { key: 4, value: data.doctor.phone },
                  {
                    key: 5,
                    value: new Date(data.date).toLocaleDateString("en-uk"),
                  },
                ],
              }))}
              label="Recent Appointments"
              blueHead
            />
          </div>
        </div>
      )}
    </AdminDashboardlayout>
  );
}

export default AdminDashboard;
