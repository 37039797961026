/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";
import { getAccessToken, getAdminAccessToken } from "../accessToken";

class Profile {
  async getProfile(role) {
    const accessToken =
      role === "user" ? getAccessToken() : getAdminAccessToken();

    const response = await fetch(`${baseURL}/profile`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to get ${role} profile` };
  }
}

const instance = new Profile();

export default instance;
