import { Toaster } from "react-hot-toast";
import AppProvider from "./AppProvider";
import Router from "./router/Router";

function App() {
  return (
    <div>
      <Toaster />
      <AppProvider>
        <Router />
      </AppProvider>
    </div>
  );
}

export default App;
