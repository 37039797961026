import { useState } from "react";
import Video from "../../../utils/apiServices/video";
import videoIcon from "../../../assets/icons/video-icon.svg";
import { ReactComponent as Spinner } from "../../../assets/icons/buttonSpinner.svg";
import notify from "../../../utils/notify";

function ChatBoxHeader({
  chatPage,
  setChatPage,
  setInCall,
  setCallConfig,
  conversationId,
  messageableId,
}) {
  const [loading, setLoading] = useState(false);
  const pages = [
    { label: "Messages", value: "messages" },
    { label: "Diagnosis", value: "diagnosis" },
    { label: "Profile", value: "profile" },
  ];

  const initializeCall = () => {
    setLoading(true);
    Video.callUser({ conversationId, messageableId })
      .then((res) => {
        setLoading(false);
        if (res.status) {
          const { data } = res;

          setCallConfig(data);
          setInCall(true);
        } else {
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "connection error");
      });
  };

  return (
    <div className="chat-box-header">
      <div className="left">
        {pages.map((page) => (
          <p
            key={page.value}
            className={`${page.value === chatPage ? "active" : ""}`}
            onClick={() => {
              setChatPage(page.value);
            }}
          >
            {page.label}
          </p>
        ))}
      </div>
      <div className="right" onClick={initializeCall}>
        {loading ? <Spinner /> : <img src={videoIcon} alt="" />}
      </div>
    </div>
  );
}

export default ChatBoxHeader;
