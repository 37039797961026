import Echo from "laravel-echo";
import io from "socket.io-client";
import { getAccessToken } from "./accessToken";

const URL = "https://api.eretinacare.com:6001";

window.io = io;

export function createSocketConnection() {
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: URL,
      transports: ["websocket", "polling", "flashsocket"],
      auth: {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
      namespace: "Musonza.Chat.Eventing",
    });
  }
}

export const messageListeningEvent = "MessageWasSent";
