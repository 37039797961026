import "./cards.css";

function AnalyticsCard({ icon, value, name, iconBackgroundColor }) {
  return (
    <div className="analytics-card">
      <div className="left" style={{ backgroundColor: iconBackgroundColor }}>
        <img src={icon} alt="" />
      </div>
      <div className="right">
        <h3>{value}</h3>
        <p>{name}</p>
      </div>
    </div>
  );
}

export default AnalyticsCard;
