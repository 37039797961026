/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";
import { getAdminAccessToken } from "../accessToken";

class Appointments {
  async getAppointments() {
    const accessToken = getAdminAccessToken();

    const response = await fetch(`${baseURL}/admin/appointments`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to get appointments` };
  }
}

const instance = new Appointments();

export default instance;
