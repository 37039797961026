import search from "../../../assets/icons/search.svg";
import "./search.css";

function Search({ searchKey, items, setItems, placeholder }) {
  const onSearch = (e) => {
    const filteredItems = items.filter((item) =>
      String(item[searchKey])
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    );

    setItems(filteredItems);
  };

  return (
    <div className="search">
      <input type="search" placeholder={placeholder} onChange={onSearch} />
      <img src={search} alt="" />
    </div>
  );
}

export default Search;
