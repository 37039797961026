import "./table.css";

function Table({
  tableHead,
  tableBody,
  label,
  darkHead,
  blueHead,
  removeHead,
}) {
  const tableLength = () => {
    if (tableHead.length > 5) {
      return "longer-table";
    }
    if (tableHead.length > 4) {
      return "long-table";
    }

    return "";
  };
  return (
    <div
      className={`table-wrapper ${darkHead ? "dark-head" : ""} ${
        blueHead ? "blue-head" : ""
      } ${removeHead ? "remove-head" : ""}`}
    >
      {label && <p className="label">{label}</p>}
      <table className={tableLength()}>
        <thead>
          <tr>
            {tableHead.map((item) => (
              <th key={item.key}>{item.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableBody.map((item) => (
            <tr key={item.key}>
              {item.entry.map((entry) => (
                <td key={entry.key}>{entry.value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
