import AdminDashboard from "../../pages/adminDashboard/AdminDashboard";
import Consultants from "../../pages/consultants/Consultants";
import ConsultantsList from "../../pages/consultants/ConsultantsList";
import AddConsultant from "../../pages/consultants/AddConsultant";
import HealthCenters from "../../pages/healthCenters/HealthCenters";
import HealthCentersList from "../../pages/healthCenters/HealthCentersList";
import AddHealthCenter from "../../pages/healthCenters/AddHealthCenter";

const adminRoutes = [
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/consultants",
    component: Consultants,
    protected: true,
    role: "admin",
    nestedRoute: [
      {
        path: "",
        component: ConsultantsList,
      },
      {
        path: "add",
        component: AddConsultant,
      },
    ],
  },
  {
    path: "/admin/healthcenters",
    component: HealthCenters,
    protected: true,
    role: "admin",
    nestedRoute: [
      {
        path: "",
        component: HealthCentersList,
      },
      {
        path: "add",
        component: AddHealthCenter,
      },
    ],
  },
];

export default adminRoutes;
