import { useContext } from "react";
import { AppContext } from "../AppContext";
import Paitents from "../utils/apiServices/patients";
import notify from "../utils/notify";

function useUpdatePatients() {
  const { patients, setPatients } = useContext(AppContext);
  function updatePatients() {
    setPatients({
      ...patients,
      loading: true,
    });

    Paitents.getPatients()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setPatients({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setPatients({
            ...patients,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setPatients({
          ...patients,
          loading: false,
        });
      });
  }

  return { updatePatients };
}

export default useUpdatePatients;
