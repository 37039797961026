import { useEffect } from "react";

import { createSocketConnection } from "../utils/socket";

import { messageListeningEvent } from "../utils/socket";

// callBack: (payload: any) => void
// channel: string
// eevnt sting
function listen(callBack, channel, event) {
  window.Echo.private(channel).listen(event, (payload) => {
    callBack(payload);
  });

  return function cleanUp() {
    window.Echo.leaveChannel(`private-${channel}`);
  };
}

// callBack: (payload: any) => void;
// coversation: string;
export const useSocket = ({ callBack, conversationId }) => {
  useEffect(() => {
    if (conversationId) {
      createSocketConnection();
      return listen(
        callBack,
        `mc-chat-conversation.${conversationId}`,
        messageListeningEvent
      );
    }
  });
};
