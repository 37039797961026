import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import PageLoading from "../../components/ui/loader/pageLoading";
import HcLayout from "../../components/hcLayout";
import AnalyticsCard from "../../components/ui/cards/AnalyticsCard";
import AppointmentCard from "../../components/ui/cards/AppointmentCard";
import user from "../../assets/icons/user.svg";
import userMultiple from "../../assets/icons/user-multiple.svg";
import userPic from "../../assets/images/Indian Man.png";
import "../consultantDashboard/consultantDashboard.css";

// hooks
import useUpdateHealthCenters from "../../hooks/healthCenters";

function HcDashboard() {
  const { healthCenterPatients, healthCenterAppointments } =
    useContext(AppContext);
  const { updateHealthCenterPatients, updateHealthCenterAppointments } =
    useUpdateHealthCenters();

  const loading = healthCenterPatients.loading;

  useEffect(() => {
    // fetch patients and appointments
    if (healthCenterPatients.loading) {
      updateHealthCenterPatients();
    }
    if (healthCenterAppointments.loading) {
      updateHealthCenterAppointments();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <HcLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="dashboard">
          <div className="analytics-cards">
            <AnalyticsCard
              icon={user}
              value={healthCenterPatients.data.length}
              name="All Patients"
              iconBackgroundColor="#06D6A0"
            />
            <AnalyticsCard
              icon={userMultiple}
              value={healthCenterAppointments.data.length}
              name="All Appointments"
              iconBackgroundColor="#F2994A"
            />
          </div>
          <div className="appointment-patient-cards">
            <div className="patients-cards">
              <h3>New Patients</h3>
              {healthCenterPatients.data.map((appointment) => (
                <AppointmentCard
                  key={appointment.id}
                  pic={userPic}
                  name={appointment.name}
                  date={new Date(appointment.created_at).toLocaleDateString(
                    "en-uk"
                  )}
                />
              ))}
            </div>
            <div className="appoitment-cards">
              <h3>New Appointments</h3>
              {healthCenterAppointments.data.map((appointment) => (
                <AppointmentCard
                  key={appointment.id}
                  pic={userPic}
                  name={appointment.patient.name}
                  date={new Date(appointment.created_at).toLocaleDateString(
                    "en-UK"
                  )}
                  time={new Date(appointment.created_at).toLocaleTimeString(
                    "en-US",
                    { hour: "2-digit", minute: "2-digit" }
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </HcLayout>
  );
}

export default HcDashboard;
