import ChatList from "./components/ChatList";

function ChatBase() {
  return (
    <div className="chats chat-base">
      <ChatList />
    </div>
  );
}

export default ChatBase;
