import { useContext } from "react";
import { AppContext } from "../AppContext";
import Consultants from "../utils/apiServices/consultants";
import notify from "../utils/notify";

function useUpdateConsultants() {
  const {
    consultants,
    setConsultants,
    consultantPatients,
    setConsultantPatients,
    consultantAppointments,
    setConsultantAppointments,
  } = useContext(AppContext);

  function updateConsultants() {
    setConsultants({
      ...consultants,
      loading: true,
    });

    Consultants.getConsultants()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setConsultants({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setConsultants({
            ...consultants,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setConsultants({
          ...consultants,
          loading: false,
        });
      });
  }

  function updateConsultantPatients() {
    setConsultantPatients({
      ...consultantPatients,
      loading: true,
    });

    Consultants.getConsultantPatients()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setConsultantPatients({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setConsultantPatients({
            ...consultantPatients,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setConsultantPatients({
          ...consultantPatients,
          loading: false,
        });
      });
  }

  function updateConsultantAppointments() {
    setConsultantAppointments({
      ...consultantAppointments,
      loading: true,
    });

    Consultants.getConsultantAppointments()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setConsultantAppointments({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setConsultantAppointments({
            ...consultantAppointments,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setConsultantAppointments({
          ...consultantAppointments,
          loading: false,
        });
      });
  }

  return {
    updateConsultants,
    updateConsultantPatients,
    updateConsultantAppointments,
  };
}

export default useUpdateConsultants;
