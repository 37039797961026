import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import FormInput from "../../components/form/formInput/FormInput";
import FormButton from "../../components/form/formButton/FormButton";
import notify from "../../utils/notify";
import "./login.css";

// api
import Authentication from "../../utils/apiServices/authentication";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const rememberMe = false;
  const [submitting, setSubmitting] = useState(false);
  const { user, setUser, admin, setAdmin } = useContext(AppContext);
  const navigate = useNavigate();

  const onLogin = (e) => {
    e.preventDefault();
    setSubmitting(true);
    Authentication.login({ email, password })
      .then((res) => {
        setSubmitting(false);
        if (res.status) {
          const { data } = res;
          if (data.role === "doctor" || data.role === "user") {
            sessionStorage.setItem("accessToken", data.token.access_token);
            if (rememberMe) {
              localStorage.setItem("accessToken", data.token.access_token);
            }
            setUser({
              id: data.user.id,
              uuid: data.user.uuid,
              email: data.user.email,
              name: data.user.name,
              hospital: data.user.hospital,
              address: data.user.address,
              phone: data.user.phone,
              profile_picture: data.user.profile_photo_url,
              created_at: data.created_at,
              loggedIn: true,
              role: data.role,
            });
            notify("success", "welcome");
          } else if (data.role === "superadmin") {
            sessionStorage.setItem("adminAccessToken", data.token.access_token);
            if (rememberMe) {
              localStorage.setItem("adminAccessToken", data.token.access_token);
            }
            setAdmin({
              id: data.user.id,
              uuid: data.user.uuid,
              email: data.user.email,
              name: data.user.name,
              phone: data.user.phone,
              profile_picture: data.user.profile_photo_url,
              loggedIn: true,
            });
            notify("success", "welcome admin");
          } else {
            notify("error", "Unauthorized role");
          }
        } else {
          notify("error", res.message);
        }
      })
      .catch(() => {
        setSubmitting(false);
        notify("error", "check your connection");
      });
  };

  useEffect(() => {
    if (user.loggedIn && user.role === "user") {
      return navigate("/health-center/dashboard");
    }

    if (user.loggedIn && user.role === "doctor") {
      return navigate("/consultant/dashboard");
    }

    if (admin.loggedIn) {
      return navigate("/admin/dashboard");
    }

    // eslint-disable-next-line
  }, [user.loggedIn, admin.loggedIn]);

  return user.loggedIn || admin.loggedIn ? null : (
    <div className="login">
      <div className="login-left">
        <div className="form-card">
          <form onSubmit={onLogin}>
            <h2>Login to your account</h2>
            <FormInput
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              dark
              showIcon
            />
            <FormInput
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              dark
              showIcon
            />
            {/* <p className="fp">Forgot Password?</p>
            <p className="su">Don't have an account? Sign up Now!</p> */}
            <FormButton
              label="Login"
              loading={submitting}
              color="#fff"
              backgroundColor="#007aff"
            />
          </form>
        </div>
      </div>
      <div className="login-right" />
    </div>
  );
}

export default Login;
