/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";
import { getAdminAccessToken, getAccessToken } from "../accessToken";

class HealthCenters {
  async createHealthCenter(data) {
    const accessToken = getAdminAccessToken();

    const response = await fetch(`${baseURL}/auth/register`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to create health center`,
    };
  }

  async getHealthCenters() {
    const accessToken = getAdminAccessToken();

    const response = await fetch(`${baseURL}/admin/healthcenters`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to get health centers` };
  }

  async getHealthCenterPatients() {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/patients`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to get patients`,
    };
  }

  async getHealthCenterAppointments() {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/patients/appointments`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to get appointments`,
    };
  }

  async uploadPatientFundus({ patientId, data }) {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/patients/${patientId}/images`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    });

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to upload image`,
    };
  }
}

const instance = new HealthCenters();

export default instance;
