import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import PageLoading from "../../components/ui/loader/pageLoading";
import Dashboardlayout from "../../components/layout";
import AnalyticsCard from "../../components/ui/cards/AnalyticsCard";
import AppointmentCard from "../../components/ui/cards/AppointmentCard";
import user from "../../assets/icons/user.svg";
import userMultiple from "../../assets/icons/user-multiple.svg";
import userPic from "../../assets/images/Indian Man.png";
import "./consultantDashboard.css";

// hooks
import useUpdateConsultants from "../../hooks/consultants";

function ConsultantDashboard() {
  const { consultantPatients, consultantAppointments } = useContext(AppContext);
  const { updateConsultantPatients, updateConsultantAppointments } =
    useUpdateConsultants();

  const loading = consultantPatients.loading || consultantAppointments.loading;

  useEffect(() => {
    // fetch patients and appointments
    if (consultantPatients.loading) {
      updateConsultantPatients();
    }
    if (consultantAppointments.loading) {
      updateConsultantAppointments();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Dashboardlayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="dashboard">
          <div className="analytics-cards">
            <AnalyticsCard
              icon={user}
              value={consultantPatients.data.length}
              name="All Patients"
              iconBackgroundColor="#06D6A0"
            />
            <AnalyticsCard
              icon={userMultiple}
              value={consultantAppointments.data.length}
              name="All Appointments"
              iconBackgroundColor="#F2994A"
            />
          </div>
          <div className="appointment-patient-cards">
            <div className="appoitment-cards">
              <h3>New Appointments</h3>
              {consultantAppointments.data.map((appointment) => (
                <AppointmentCard
                  key={appointment.id}
                  pic={userPic}
                  name={appointment.patient.name}
                  date={new Date(appointment.created_at).toLocaleDateString(
                    "en-UK"
                  )}
                  time={new Date(appointment.created_at).toLocaleTimeString(
                    "en-US",
                    { hour: "2-digit", minute: "2-digit" }
                  )}
                />
              ))}
            </div>
            <div className="patients-cards">
              <h3>New Patients</h3>
              {consultantPatients.data.map((appointment) => (
                <AppointmentCard
                  key={appointment.id}
                  pic={userPic}
                  name={appointment.name}
                  date={new Date(appointment.created_at).toLocaleDateString(
                    "en-uk"
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Dashboardlayout>
  );
}

export default ConsultantDashboard;
