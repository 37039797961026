import "./cards.css";

function AppointmentCard({ pic, name, date, time }) {
  return (
    <div className="appointment-card">
      <div className="left">
        <img src={pic} alt="" />
        <div>
          <h4>{name}</h4>
          <p>Patient</p>
        </div>
      </div>
      <div className="right">
        <p>{date}</p>
        <p>{time}</p>
      </div>
    </div>
  );
}

export default AppointmentCard;
