import { useContext } from "react";
import { AppContext } from "../AppContext";
import HealthCenters from "../utils/apiServices/healthCenters";
import notify from "../utils/notify";

function useUpdateHealthCenters() {
  const {
    healthCenters,
    setHealthCenters,
    healthCenterPatients,
    setHealthCenterPatients,
    healthCenterAppointments,
    setHealthCenterAppointments,
  } = useContext(AppContext);

  function updateHealthCenters() {
    setHealthCenters({
      ...healthCenters,
      loading: true,
    });

    HealthCenters.getHealthCenters()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setHealthCenters({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setHealthCenters({
            ...healthCenters,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setHealthCenters({
          ...healthCenters,
          loading: false,
        });
      });
  }

  function updateHealthCenterPatients() {
    setHealthCenterPatients({
      ...healthCenterPatients,
      loading: true,
    });

    HealthCenters.getHealthCenterPatients()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setHealthCenterPatients({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setHealthCenterPatients({
            ...healthCenterPatients,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setHealthCenterPatients({
          ...healthCenterPatients,
          loading: false,
        });
      });
  }

  function updateHealthCenterAppointments() {
    setHealthCenterAppointments({
      ...healthCenterAppointments,
      loading: true,
    });

    HealthCenters.getHealthCenterAppointments()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setHealthCenterAppointments({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setHealthCenterAppointments({
            ...healthCenterAppointments,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setHealthCenterAppointments({
          ...healthCenterAppointments,
          loading: false,
        });
      });
  }

  return {
    updateHealthCenters,
    updateHealthCenterPatients,
    updateHealthCenterAppointments,
  };
}

export default useUpdateHealthCenters;
