import { useState, useMemo } from "react";
import { AppContext } from "./AppContext";

function AppProvider({ children }) {
  const [user, setUser] = useState({
    loggedIn: false,
  });

  const [admin, setAdmin] = useState({
    loggedIn: false,
  });

  const [consultants, setConsultants] = useState({
    data: [],
    loading: true,
  });

  const [consultantPatients, setConsultantPatients] = useState({
    data: [],
    loading: true,
  });

  const [consultantAppointments, setConsultantAppointments] = useState({
    data: [],
    loading: true,
  });

  const [healthCenters, setHealthCenters] = useState({
    data: [],
    loading: true,
  });

  const [healthCenterPatients, setHealthCenterPatients] = useState({
    data: [],
    loading: true,
  });

  const [healthCenterAppointments, setHealthCenterAppointments] = useState({
    data: [],
    loading: true,
  });

  const [patients, setPatients] = useState({
    data: [],
    loading: true,
  });

  const [appointments, setAppointments] = useState({
    data: [],
    loading: true,
  });

  const [myAppointments, setMyAppointments] = useState({
    data: [],
    loading: true,
  });

  const [conversations, setConversations] = useState({
    data: [],
    loading: true,
  });

  const [messages, setMessages] = useState({});

  const [diagnoses, setDiagnoses] = useState({});

  const providerValue = useMemo(
    () => ({
      user,
      setUser,
      admin,
      setAdmin,
      consultants,
      setConsultants,
      consultantPatients,
      setConsultantPatients,
      consultantAppointments,
      setConsultantAppointments,
      healthCenters,
      setHealthCenters,
      healthCenterPatients,
      setHealthCenterPatients,
      healthCenterAppointments,
      setHealthCenterAppointments,
      patients,
      setPatients,
      appointments,
      setAppointments,
      myAppointments,
      setMyAppointments,
      conversations,
      setConversations,
      messages,
      setMessages,
      diagnoses,
      setDiagnoses,
    }),
    [
      user,
      setUser,
      admin,
      setAdmin,
      consultants,
      setConsultants,
      consultantPatients,
      setConsultantPatients,
      consultantAppointments,
      setConsultantAppointments,
      healthCenters,
      setHealthCenters,
      healthCenterPatients,
      setHealthCenterPatients,
      healthCenterAppointments,
      setHealthCenterAppointments,
      patients,
      setPatients,
      appointments,
      setAppointments,
      myAppointments,
      setMyAppointments,
      conversations,
      setConversations,
      messages,
      setMessages,
      diagnoses,
      setDiagnoses,
    ]
  );

  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
}

export default AppProvider;
