import { useState } from "react";
import { useLocation } from "react-router-dom";
import PanelHeader from "./PanelHeader";
import PanelSideNavigation from "./PanelSideNavigation";
import "../layout/layout.css";

function HcLayout({ children }) {
  const [showSideNav, setShowSideNav] = useState(false);

  const location = useLocation();

  const page = location.pathname.split("/")[2];

  const toggleSideNav = () => {
    setShowSideNav(!showSideNav);
  };

  return (
    <div className="layout">
      <PanelHeader page={page} onClickMenu={toggleSideNav} />
      <PanelSideNavigation
        type="desktop"
        page={page}
        closeSideNav={toggleSideNav}
      />
      {showSideNav && (
        <PanelSideNavigation
          type="mobile"
          page={page}
          closeSideNav={toggleSideNav}
        />
      )}
      {children}
    </div>
  );
}

export default HcLayout;
