import { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AppContext } from "../../AppContext";
import ChatList from "./components/ChatList";
import ChatBox from "./components/ChatBox";
import { useSocket } from "../../hooks/socket";
import useUpdateChats from "../../hooks/chats";

function ChatDetail() {
  const [validId, setValidId] = useState(false);
  const { conversations, setConversations, setMessages, user } =
    useContext(AppContext);
  const { conversationId } = useParams();
  const [searchParams] = useSearchParams();

  const participantId = searchParams.get("participant_id");
  const messageableId = searchParams.get("messageable_id");
  const participantType = searchParams.get("participant_type");

  const { updateConversations } = useUpdateChats();

  useEffect(() => {
    setValidId(false);
    // validate the conversation id
    const isValidId = conversations.data.find(
      (conversation) => String(conversation.conversation_id) === conversationId
    );
    if (isValidId) {
      setValidId(true);
    }

    // eslint-disable-next-line
  }, [conversationId, conversations.data]);

  useSocket({
    conversationId,
    callBack: (payload) => {
      updateConversations();

      // update conversation messages

      // // change the logic below to get the message based on the id
      // // instead of hoping for the message to always be at the last index
      setMessages((prevMessages) => {
        if (payload.message.sender.id === user.id) {
          const newMessages = prevMessages;

          newMessages[conversationId][prevMessages[conversationId].length - 1] =
            payload.message;

          return newMessages;
        }

        return {
          ...prevMessages,
          [conversationId]: [...prevMessages[conversationId], payload.message],
        };
      });

      // update coversation
      setConversations((prevConvo) => {
        const newConvo = prevConvo;

        const newConvoData = prevConvo.data.map((convo, i) => {
          if (convo.id === Number(conversationId)) {
            return {
              ...convo,
              conversations: {
                ...convo.converstion,
                last_message: { body: payload.message.body },
              },
            };
          }

          return convo;
        });

        return {
          ...newConvo,
          data: newConvoData,
        };
      });
    },
  });

  return (
    <div className="chats chat-detail">
      <ChatList />
      {validId && (
        <ChatBox
          conversationId={conversationId}
          participantId={participantId}
          messageableId={messageableId}
          participantType={participantType}
        />
      )}
    </div>
  );
}

export default ChatDetail;
