import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Authentication from "../../../utils/apiServices/authentication";

import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Home } from "../../../assets/icons/home.svg";
import { ReactComponent as Patients } from "../../../assets/icons/patients.svg";
import { ReactComponent as Messages } from "../../../assets/icons/messages.svg";
import { ReactComponent as Profile } from "../../../assets/icons/profile.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logoutIcon.svg";
import { ReactComponent as Spinner } from "../../../assets/icons/buttonSpinner.svg";
import "./PanelSideNavigation.css";
import notify from "../../../utils/notify";

// hook
import useLogout from "../../../hooks/user";

function PanelSideNavigation({ type, closeSideNav, page }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const [loggingOut, setLoggingOut] = useState(false);

  const navigate = useNavigate();

  const { logout } = useLogout();

  const navigationMiddleItems = [
    {
      icon: Home,
      to: "/consultant/dashboard",
      text: "Dashboard",
    },
    {
      icon: Patients,
      to: "/consultant/patients",
      text: "Patients",
    },
    {
      icon: Messages,
      to: "/consultant/consultations",
      text: "Consultations",
    },
    {
      icon: Profile,
      to: "/consultant/profile",
      text: "Profile",
    },
  ];

  const onLogout = () => {
    setLoggingOut(true);
    Authentication.logout()
      .then((res) => {
        if (res.status) {
          logout();
          setLoggingOut(false);
        } else {
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoggingOut(false);
      });
  };

  const onHideMenu = (route) => {
    setMenuOpen(false);
    setTimeout(() => {
      if (route) navigate(route);
      closeSideNav();
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      setMenuOpen(true);
    }, 50);
  }, []);

  return (
    <div
      className={`PanelSideNavigation${menuOpen ? " nav-visible" : ""}${
        type === "desktop"
          ? " desktop-side-navigation"
          : " mobile-side-navigation"
      }`}
    >
      <div
        className="panel-sidenavigation-background"
        onClick={() => {
          onHideMenu();
        }}
      />
      <div className="panel-sidenavigation-content-wrapper">
        <div className="logo-wrapper">
          <Close onClick={() => onHideMenu()} />
        </div>
        <div className="navigation-middle-items">
          <ul className="desktop-ul">
            {navigationMiddleItems.map((item) => (
              <li
                key={item.to}
                onClick={() => navigate(item.to)}
                className={
                  page === item.to.split("/")[2] ? "active-navigation" : ""
                }
              >
                <item.icon />
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
          <ul className="mobile-ul">
            {navigationMiddleItems.map((item) => (
              <li
                key={item.to}
                onClick={() => onHideMenu(item.to)}
                className={
                  page === item.to.split("/")[2] ? "active-navigation" : ""
                }
              >
                <item.icon />
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
          <ul className="bottom-ul desktop-ul">
            <li
              className={loggingOut ? "disabled" : ""}
              onClick={() => (!loggingOut ? onLogout() : null)}
            >
              {loggingOut ? <Spinner className="spinner" /> : <Logout />}
              <p>Logout</p>
            </li>
          </ul>
          <ul className="bottom-ul mobile-ul">
            <li
              className={loggingOut ? "disabled" : ""}
              onClick={() => (!loggingOut ? onLogout() : null)}
            >
              {loggingOut ? <Spinner className="spinner" /> : <Logout />}
              <p>Logout</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PanelSideNavigation;
