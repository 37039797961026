import { ReactComponent as Spinner } from "../../../assets/icons/buttonSpinner.svg";
import "./formButton.css";

function FormButton({ label, error, loading, small, color, backgroundColor }) {
  return (
    <button
      style={{ color, backgroundColor }}
      disabled={loading || error}
      className={`FormButton${error ? " formbutton-error" : ""}${
        loading ? " formbutton-loading" : ""
      }${small ? " formbutton-small" : ""}`}
      type="submit"
    >
      {loading && <Spinner className="spinner" />}
      {label}
    </button>
  );
}

export default FormButton;
