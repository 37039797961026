import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import PageLoading from "../../components/ui/loader/pageLoading";
import Button from "../../components/ui/button/Button";
import Search from "../../components/ui/search/Search";
import Table from "../../components/ui/table/Table";
import plus from "../../assets/icons/plus.svg";
import edit from "../../assets/icons/edit.png";
import trash from "../../assets/icons/trash.png";

function HealthCentersList() {
  const { healthCenters } = useContext(AppContext);

  const [filteredHealthCenters, setFilteredHealthCenters] = useState(
    healthCenters.data
  );

  const navigate = useNavigate();

  useEffect(() => {
    setFilteredHealthCenters(healthCenters.data);
  }, [healthCenters.data]);

  return healthCenters.loading ? (
    <PageLoading />
  ) : (
    <div className="consultants-list">
      <div className="consultants-list-header">
        <Search
          searchKey="hospital"
          items={healthCenters.data}
          setItems={setFilteredHealthCenters}
          placeholder="search by health center"
        />
        <Button
          icon={plus}
          text="New Health Center"
          color="#fff"
          backgroundColor="#06D6A0"
          big
          onClick={() => {
            navigate("/admin/healthcenters/add");
          }}
        />
      </div>
      <div className="consultants-list-table">
        <Table
          tableHead={[
            { key: 1, value: "Health Center" },
            { key: 2, value: "Name of Operator" },
            { key: 3, value: "No. of Patients" },
            { key: 4, value: "Creation Date" },
            { key: 5, value: "Action" },
          ]}
          tableBody={filteredHealthCenters.map((data) => ({
            key: data.id,
            entry: [
              { key: 1, value: data.hospital },
              { key: 2, value: data.name },
              { key: 3, value: data.patients.length },
              {
                key: 4,
                value: new Date(data.created_at).toLocaleDateString("en-uk"),
              },
              {
                key: 5,
                value: (
                  <div style={{ display: "flex" }}>
                    <Button icon={edit} />
                    <Button icon={trash} />
                  </div>
                ),
              },
            ],
          }))}
          blueHead
        />
      </div>
    </div>
  );
}

export default HealthCentersList;
