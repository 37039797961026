import HcDashboard from "../../pages/hcDashboard/HcDashboard";
import HcPatients from "../../pages/hcPatients/HcPatients";
import HcPatientDetails from "../../pages/hcPatients/HcPatientDetails";
import Chats from "../../pages/chats/Chats";
import ChatBase from "../../pages/chats/ChatBase";
import ChatDetail from "../../pages/chats/ChatDetail";

const hcRoutes = [
  {
    path: "/health-center/dashboard",
    component: HcDashboard,
    protected: true,
    role: "user",
  },
  {
    path: "/health-center/patients",
    component: HcPatients,
    protected: true,
    role: "user",
  },
  {
    path: "/health-center/patients/:uuid",
    component: HcPatientDetails,
    protected: true,
    role: "user",
  },
  {
    path: "/health-center/consultations",
    component: Chats,
    protected: true,
    role: "user",
    nestedRoute: [
      {
        path: "",
        component: ChatBase,
      },
      {
        path: ":conversationId/messages",
        component: ChatDetail,
      },
    ],
  },
];

export default hcRoutes;
