import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import Dashboardlayout from "../../components/layout";
import PageLoading from "../../components/ui/loader/pageLoading";
import Table from "../../components/ui/table/Table";
import Button from "../../components/ui/button/Button";
import joseph from "../../assets/images/joseph.png";

// hook
import useUpdateConsultants from "../../hooks/consultants";

function ConsultantPatients() {
  const { consultantPatients } = useContext(AppContext);
  const { updateConsultantPatients } = useUpdateConsultants();

  const navigate = useNavigate();

  useEffect(() => {
    // fetch patients, health centers, consultants and appointments
    if (consultantPatients.loading) {
      updateConsultantPatients();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dashboardlayout>
      {consultantPatients.loading ? (
        <PageLoading />
      ) : (
        <Table
          tableHead={[
            { key: 1, value: "pic" },
            { key: 2, value: "name" },
            { key: 3, value: "Gender" },
            { key: 4, value: "Age" },
            { key: 5, value: "Date created" },
            { key: 6, value: "Action" },
          ]}
          tableBody={consultantPatients.data.map((patient) => ({
            key: patient.id,
            entry: [
              { key: 1, value: <img src={joseph} alt="" /> },
              { key: 2, value: patient.name },
              { key: 3, value: patient.gender },
              {
                key: 4,
                value: `${Math.floor(
                  (new Date() - new Date(patient.dob)) / 31536000000
                )} years`,
              },
              {
                key: 5,
                value: new Date(patient.created_at).toLocaleDateString("en-uk"),
              },
              {
                key: 6,
                value: (
                  <Button
                    text="View"
                    backgroundColor="#fff"
                    color="#007aff"
                    type="outlined"
                    onClick={() =>
                      navigate(`/consultant/patients/${patient.uuid}`)
                    }
                  />
                ),
              },
            ],
          }))}
          blueHead
        />
      )}
    </Dashboardlayout>
  );
}

export default ConsultantPatients;
