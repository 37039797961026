import { useContext } from "react";
import { AppContext } from "../AppContext";

function useLogout() {
  const {
    setUser,
    setAdmin,
    setConsultants,
    setConsultantPatients,
    setConsultantAppointments,
    setHealthCenters,
    setPatients,
    setAppointments,
    setMyAppointments,
    setConversations,
    setMessages,
  } = useContext(AppContext);

  function logout() {
    setUser({});
    setAdmin({});
    setConsultants({
      data: [],
      loading: true,
    });
    setConsultantPatients({
      data: [],
      loading: true,
    });
    setConsultantAppointments({
      data: [],
      loading: true,
    });
    setHealthCenters({
      data: [],
      loading: true,
    });
    setPatients({
      data: [],
      loading: true,
    });
    setAppointments({
      data: [],
      loading: true,
    });
    setMyAppointments({
      data: [],
      loading: true,
    });
    setConversations({
      data: [],
      loading: true,
    });

    setMessages({});
  }

  return { logout };
}

export default useLogout;
