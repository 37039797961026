import { useContext } from "react";
import { AppContext } from "../AppContext";
import Appointments from "../utils/apiServices/appointments";
import notify from "../utils/notify";

function useUpdateAppointments() {
  const { appointments, setAppointments } = useContext(AppContext);
  function updateAppointments() {
    setAppointments({
      ...appointments,
      loading: true,
    });

    Appointments.getAppointments()
      .then((res) => {
        if (res.status) {
          const { data } = res;

          setAppointments({
            data,
            loading: false,
          });
        } else {
          notify("error", res.message);
          setAppointments({
            ...appointments,
            loading: false,
          });
        }
      })
      .catch(() => {
        notify("error", "connection error");
        setAppointments({
          ...appointments,
          loading: false,
        });
      });
  }

  return { updateAppointments };
}

export default useUpdateAppointments;
