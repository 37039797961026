import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUpdateHealthCenters from "../../hooks/healthCenters";
import FormInput from "../../components/form/formInput/FormInput";
import FormButton from "../../components/form/formButton/FormButton";
import Button from "../../components/ui/button/Button";

// api service
import HealthCenters from "../../utils/apiServices/healthCenters";
import notify from "../../utils/notify";

function AddHealthCenter() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hospital, setHospital] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { updateHealthCenters } = useUpdateHealthCenters();

  const addHealthCenter = (e) => {
    e.preventDefault();
    setSubmitting(true);
    HealthCenters.createHealthCenter({
      name,
      email,
      password,
      password_confirmation: confirmPassword,
      phone,
      address,
      hospital,
    })
      .then((res) => {
        if (res.status) {
          setSubmitting(false);
          updateHealthCenters();
          notify("success", res.message);
          navigate("/admin/healthcenters");
        } else {
          setSubmitting(false);
          notify("error", res.message);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        notify("error", "An error occured. Check your connection");
      });
  };

  return (
    <div className="add-consultant">
      <form onSubmit={addHealthCenter}>
        <FormInput
          type="text"
          placeholder="Enter health center name"
          value={hospital}
          onChange={(e) => setHospital(e.target.value)}
          required
          leftLabel="Health Center Name"
          name="hospital"
          small
        />
        <FormInput
          type="text"
          placeholder="Enter operator name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          leftLabel="Operator Name"
          name="name"
          small
        />
        <FormInput
          type="email"
          placeholder="Enter email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          leftLabel="Email"
          name="email"
          small
        />
        <FormInput
          type="tel"
          placeholder="Enter phone number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          leftLabel="Phone Number"
          name="phone"
          small
        />
        <FormInput
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          leftLabel="Password"
          name="password"
          small
        />
        <FormInput
          type="password"
          placeholder="Repeat password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          leftLabel="Confirm Password"
          name="confirm_password"
          small
        />
        <FormInput
          type="text"
          placeholder="Enter presiding hospital address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
          leftLabel="Address"
          name="address"
          small
        />
        <div className="form-button-wrapper">
          <div className="submit-button-wrapper">
            <FormButton
              label="Submit"
              loading={submitting}
              color="#fff"
              backgroundColor="#06D6A0"
            />
          </div>
          <Button
            text="Cancel"
            color="#fff"
            backgroundColor="#464646"
            onClick={() => navigate("/admin/healthcenters")}
            big
            long
          />
        </div>
      </form>
    </div>
  );
}

export default AddHealthCenter;
