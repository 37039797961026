import "./cards.css";

function ReceivedMessageCard({ text, initial, loading, name, pic, timestamp }) {
  return (
    <div
      className={`message-card received-message-card ${
        initial ? "initial" : ""
      }`}
    >
      {initial && <p className="name">{name}</p>}
      <p className="text">{text}</p>
      <p className="time">
        {new Date(timestamp).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </p>
    </div>
  );
}

export default ReceivedMessageCard;
