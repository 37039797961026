import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import Dashboardlayout from "../../components/layout";
import Table from "../../components/ui/table/Table";
import joseph from "../../assets/images/joseph.png";
import "./consultantProfile.css";

// hooks
import useUpdateConsultants from "../../hooks/consultants";

function ConsultantProfile() {
  const { user, consultantPatients, consultantAppointments } =
    useContext(AppContext);
  const { updateConsultantPatients, updateConsultantAppointments } =
    useUpdateConsultants();

  useEffect(() => {
    // fetch consultatnt patients
    if (consultantPatients.loading) {
      updateConsultantPatients();
    }
    // fetch consultant appointment
    if (consultantAppointments.loading) {
      updateConsultantAppointments();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Dashboardlayout>
      <div className="profile">
        <div className="profile-grid grid-1">
          <div className="profile-card">
            <div className="pic-name">
              <img src={user.profile_picture || joseph} alt="" />
              <h4>{user.name}</h4>
            </div>
            <div className="card-text-content">
              <div className="flex-row">
                <p className="label">No. of patients</p>
                <p className="value">{consultantPatients.data.length}</p>
              </div>
              <div className="flex-row">
                <p className="label">No. of Appointments</p>
                <p className="value">{consultantAppointments.data.length}</p>
              </div>
            </div>
          </div>
          <div className="profile-card">
            <h3>Details</h3>
            <div className="card-text-content">
              <p className="label">Email:</p>
              <p className="value">{user.email}</p>
              <p className="label">Phone No:</p>
              <p className="value">{user.phone}</p>
              <p className="label">Registration Date</p>
              <p className="value">
                {new Date(user.created_at).toLocaleDateString("en-uk")}
              </p>
              <p className="label">Address</p>
              <p className="value">{user.address}</p>
            </div>
          </div>
        </div>
        <div className="profile-grid grid-2">
          <Table
            label="Recent Appointments"
            tableHead={[
              { key: 1, value: "Patient's Name" },
              { key: 2, value: "Patient Phone" },
              { key: 3, value: "Date" },
              { key: 4, value: "Time" },
            ]}
            tableBody={consultantAppointments.data.map((data) => ({
              key: data.id,
              entry: [
                { key: 1, value: data.patient.name },
                { key: 2, value: data.patient.phone },
                {
                  key: 3,
                  value: new Date(data.date).toLocaleDateString("en-UK"),
                },
                {
                  key: 4,
                  value: new Date(data.date).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
                },
              ],
            }))}
            blueHead
          />
        </div>
      </div>
    </Dashboardlayout>
  );
}

export default ConsultantProfile;
