const getAccessToken = () =>
  sessionStorage.getItem("accessToken") ||
  localStorage.getItem("accessToken") ||
  "";

const getAdminAccessToken = () =>
  sessionStorage.getItem("adminAccessToken") ||
  localStorage.getItem("adminAccessToken") ||
  "";

export { getAccessToken, getAdminAccessToken };
