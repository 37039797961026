/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";
import { getAccessToken } from "../accessToken";

class Chats {
  async sendMessage({
    conversationId,
    participantId,
    participantType,
    message,
  }) {
    const accessToken = getAccessToken();

    const response = await fetch(
      `${baseURL}/chat/conversations/${conversationId}/messages?participant_id=${participantId}&participant_type=${participantType}`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          participant_id: participantId,
          participant_type: participantType,
          message: {
            body: message,
          },
        }),
      }
    );

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to create consultant` };
  }

  async getConversations() {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/conversations`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to get conversations` };
  }

  async getSingleConversation({
    conversationId,
    participantId,
    participantType,
  }) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/chat/conversations/${conversationId}/messages?participant_id=${participantId}&participant_type=${participantType}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to get single conversation`,
    };
  }
}

const instance = new Chats();

export default instance;
